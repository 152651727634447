import React, { useState } from 'react';
import { Image, Carousel, Modal, Button } from 'react-bootstrap'
import { resolveBasePath } from '../routesUtils'

const ImageGallery = (prop) => {
    const { fileNames,filePath,imageHeight,samuneHeight } = prop
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [modalImage, setModalImage] = useState('');
    const [modal, showModal] = useState(false);
    
    const handleSelect = (selectedIndex, e) => {
        setCarouselIndex(selectedIndex);
    };
    const onClick = (index) => {
        setCarouselIndex(index);
    }

    const handleCloseModal = () => showModal(false);
    const handleShowModal = (fileName) => {
        setModalImage(fileName)
        showModal(true)
    }

    return (
        <div className="mb-3 thumbs bg-secondary">
            <Carousel className='m-1 bg-secondary' interval={null} activeIndex={carouselIndex} onSelect={handleSelect}>
                {fileNames.map((fileName, index) => (
                    <Carousel.Item key={index} className=''>
                        <div className='d-flex justify-content-center'>
                        <Image 
                            className='m-3 img-fluid' 
                            style={
                                {
                                //width: deg[index] === 90 || deg[index] === 270 ? imageHeight : "auto",
                                width: "auto",
                                //height: deg[index] !== 90 && deg[index] !== 270 ? imageHeight : "auto", 
                                //height: imageHeight,
                                height: "auto",
                                maxHeight: "500px",
                            }} 
                            src={resolveBasePath(`assets/${filePath}/${fileName}`)} /*, transform: `rotate(${deg}deg)` */
                            onClick={() => handleShowModal(fileName)}
                            key={`main-${index}`}
                        />
                        <Carousel.Caption style={{color:'aqua'}} key={`caption-${index}`}>
                            {`${index+1}/${fileNames.length}`}
                        </Carousel.Caption>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className='d-flex justify-content-center flex-wrap'>
            {fileNames.map((fileName, index) => (
                <Image 
                    className={carouselIndex === index ? 'm-1 img-fluid border border-info border-4' : 'm-1 img-fluid'} 
                    style={{width: "auto",height: samuneHeight}} 
                    src={resolveBasePath(`assets/${filePath}/${fileName}`)} 
                    onClick={() => onClick(index)} 
                    key={`samune-${index}`}
                />
            ))}
            </div>
            {/*モーダル*/}
            <Modal centered show={modal} onHide={handleCloseModal} fullscreen={true}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center'>
                        <Image className='m-3 img-fluid' src={resolveBasePath(`assets/${filePath}/${modalImage}`)}/>
                    </div>
                </Modal.Body>
            </Modal>
       </div>
    )
};

export default ImageGallery