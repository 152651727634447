import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardGroup, Alert, Button, Tabs, Tab, Table, Accordion } from 'react-bootstrap'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import useCancelableEffect from '../utils/useCancelableEffect'
import useAppSelector from '../selectors/appSelector'
import useErrorMessagesService from '../services/useErrorMessagesService'
import ErrorMessages from '../components/ErrorMessages'
import Menu from '../components/Menu'

const eatDates = ['１日目','２日目','３日目']
const kubuns = ["朝食","昼食","夕食","間食"]

function DateCategory() {
    const { id } = useParams();
    const { 
        userRegisterStatus, 
        registerImages, 
        registeredMeal,
        accessToken,
        loadUser, 
        loadMealImages, 
        loadRegisteredMeal,
        setUser, 
        setDateKubun, 
    } = useAppSelector()
    const { errorMessages, initializeErrorMessages, clearErrorMessages } = useErrorMessagesService()

    const onClick = (date,kubun,fileNames) => setDateKubun(date,kubun,fileNames)

    const getRegisterStatus = (date,kubun) => {
        if(userRegisterStatus === null) return
        if(date === '１日目'){
            if(kubun === '朝食') return userRegisterStatus.firstDayBreakfast
            else if(kubun === '昼食') return userRegisterStatus.firstDayLunch
            else if(kubun === '夕食') return userRegisterStatus.firstDayDinner
            else if(kubun === '間食') return userRegisterStatus.firstDayNosh
        }
        else if(date === '２日目'){
            if(kubun === '朝食') return userRegisterStatus.secondDayBreakfast
            else if(kubun === '昼食') return userRegisterStatus.secondDayLunch
            else if(kubun === '夕食') return userRegisterStatus.secondDayDinner
            else if(kubun === '間食') return userRegisterStatus.secondDayNosh
        }
        else if(date === '３日目'){
            if(kubun === '朝食') return userRegisterStatus.thirdDayBreakfast
            else if(kubun === '昼食') return userRegisterStatus.thirdDayLunch
            else if(kubun === '夕食') return userRegisterStatus.thirdDayDinner
            else if(kubun === '間食') return userRegisterStatus.thirdDayNosh
        }
    }

    const registeredAccordion = () => {
        if(registeredMeal.errors) return
        const element = eatDates.map((eatDate,eatDateIndex) => (
                <div key={`div-${eatDateIndex}`} >
                <h1 key={`h1-${eatDateIndex}`} className='mt-2'>{eatDate}</h1>
                <Accordion key={`accordion-${eatDateIndex}`}>
                        {kubuns.map((kubun,kubunIndex) => (
                            <Accordion.Item key={`accordionItem-${eatDateIndex}-${kubunIndex}`} eventKey={kubunIndex}>
                            <Accordion.Header key={`accordionHeader-${eatDateIndex}-${kubunIndex}`} className='text-center'>{registeredMeal.length > 0 ? `${kubun}（${registeredMeal.filter(item => item.eatDate === eatDate && item.kubun === kubun)[0].mealRegister.length}件）` : ''}</Accordion.Header>
                            <Accordion.Body key={`accordionBody-${eatDateIndex}-${kubunIndex}`}>
                                <Table key={`table-${eatDateIndex}-${kubunIndex}`} striped>
                                    <thead key={`thead-${eatDateIndex}-${kubunIndex}`} >
                                        <tr key={`tr-${eatDateIndex}-${kubunIndex}`}>
                                        <th key={`th1-${eatDateIndex}-${kubunIndex}`}>#</th>
                                        <th key={`th2-${eatDateIndex}-${kubunIndex}`}>料理名</th>
                                        <th key={`th3-${eatDateIndex}-${kubunIndex}`}>実際の料理</th>
                                        <th key={`th4-${eatDateIndex}-${kubunIndex}`}>更新者</th>
                                        <th key={`th5-${eatDateIndex}-${kubunIndex}`}>更新日</th>
                                        </tr>
                                    </thead>
                                    {registeredMeal.length > 0 ? registeredMeal.filter(item => item.eatDate === eatDate && item.kubun === kubun)[0].mealRegister.map((filterItem,filterItemIndex) => (
                                        <tbody key={`tbody-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >
                                        <tr key={`tr-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >
                                        <td key={`td1-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >{filterItemIndex+1}</td>
                                        <td key={`t2-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >{filterItem.foodName}</td>
                                        <td key={`td3-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >{filterItem.eatFoodName}</td>
                                        <td key={`td4-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >{filterItem.updatedBy}</td>
                                        <td key={`td5-${eatDateIndex}-${kubunIndex}-${filterItemIndex}`} >{dayjs(filterItem.updateDate).format('YYYY-MM-DD HH:mm:ss') }</td>
                                        </tr>
                                        </tbody>
                                    )) : null}
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        ))}
                </Accordion>
                </div>
        ))
        return element
    }

    useCancelableEffect(async () => {
        setUser(id)
        if (accessToken === null) return
        /* 料理登録状況を取得 */
        const resultUser = await loadUser(id)
        if (resultUser.errors)  {
            initializeErrorMessages(resultUser.errors)
            return
        }
        /* 登録画像の取得 */
        const resultMealImages = await loadMealImages(id)
        if (resultMealImages.errors) {
            initializeErrorMessages(resultMealImages.errors)
            return
        }
        /* 対象IDの登録済み料理を取得 */
        const registeredMeal = await loadRegisteredMeal(id,'','')
        if (registeredMeal.errors) {
            initializeErrorMessages(registeredMeal.errors)
            return
        }
        clearErrorMessages()
    },[accessToken])

    return (
        <>
            <Menu />
            <h1 className="m-5">食事日付・区分</h1>
            <div className="w-50 mx-auto">
                <Alert variant="info" className="mb-5">
                    <Alert.Heading>{`被験者ID：${id}`}</Alert.Heading>
                </Alert>
                {errorMessages.length > 0 ? (
                    <ErrorMessages className="mt-3" errorMessages={errorMessages} />
                ) : null}
                <Tabs
                    defaultActiveKey="register"
                    id="fill-tab-example"
                    className="mb-3 mt-3"
                    fill
                >
                    <Tab eventKey="register" title="新規登録・編集・削除">
                        {registerImages !== null ? (registerImages.map((image,imageIndex) => (
                            image.eatDate !== null ? 
                                <div key={`div${imageIndex}`}>
                                    <h1 key={`title${imageIndex}`} className='mt-2'>{image.eatDate}</h1>
                                    <CardGroup key={`cardGroup${imageIndex}`}>
                                        {image.data.map((data,dataIndex) => (
                                            data.kubun !== null ?
                                                <Card key={`card${imageIndex}-${dataIndex}`} bg={getRegisterStatus(image.eatDate,data.kubun) === true ? 'secondary': ''}> {/* bg='secondary' */}
                                                    <Card.Header key={`cardHeader${imageIndex}-${dataIndex}`} className={getRegisterStatus(image.eatDate,data.kubun) === true ? "text-light" : ''}>{data.kubun}</Card.Header>
                                                    <Card.Body key={`cardBody${imageIndex}-${dataIndex}`} className="d-flex flex-column">
                                                        <Button key={`register${imageIndex}-${dataIndex}`} variant="primary" className='m-2' onClick={() => onClick(image.eatDate,data.kubun,data.fileName)} as={Link} to="/mealregister">新規登録する</Button>
                                                        <Button key={`edit${imageIndex}-${dataIndex}`} variant={getRegisterStatus(image.eatDate,data.kubun) === true ? "dark" : 'secondary'} className='m-2' onClick={() => onClick(image.eatDate,data.kubun,data.fileName)} as={Link} to="/mealedit">編集する</Button>
                                                        <Button key={`remove${imageIndex}-${dataIndex}`} variant="danger" className='m-2' onClick={() => onClick(image.eatDate,data.kubun,data.fileName)} as={Link} to="/mealremove">削除する</Button>
                                                    </Card.Body>
                                                </Card>
                                            : null
                                        ))}
                                    </CardGroup>
                                </div>
                            : null
                        ))) : null}
                    </Tab>
                    <Tab eventKey="registerInfo" title="登録済み情報">
                        {registeredAccordion()}
                    </Tab>
                </Tabs>
                
                <Button as={Link} to="/userlist" className='m-3' variant="secondary">
                    戻る
                </Button>
            </div>
        </>
    );
}

export default DateCategory;