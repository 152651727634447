export const SELECT_USER = 'SELECT_USER'
export const SELECT_DATE_KUBUN = 'SELECT_DATE_KUBUN'
export const REGISTER_IMAGES = 'REGISTER_IMAGES'
export const SET_SV = 'SET_SV'
export const SET_ZAIRYO = 'SET_ZAIRYO'
export const SET_USER_REGISTER_STATUS = 'SET_USER_REGISTER_STATUS'
export const SET_USERS = 'SET_USERS'
export const SET_REGISTERED_MEAL = 'SET_REGISTERED_MEAL'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SELECT_IMAGES = 'SELECT_IMAGES'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'
//export const SELECT_CATEGORY = 'SELECT_CATEGORY'

export default {
    SELECT_USER,
    SELECT_DATE_KUBUN,
    REGISTER_IMAGES,
    SET_SV,
    SET_ZAIRYO,
    SET_USER_REGISTER_STATUS,
    SET_REGISTERED_MEAL,
    CLEAR_STATE,
    SELECT_IMAGES,
    SET_USERS,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    CLEAR_TOKEN,
}