import React from 'react'
import { Alert } from 'react-bootstrap'

function ErrorMessages({ className, errorMessages }) {
  if (!errorMessages || errorMessages.length === 0) return null
  return (
    <Alert className={className} variant="danger">
      <ul className="ml-n3 mb-0">
        {errorMessages.map((error, idx) => (
          <li key={`login_errors_${idx}`}>{error}</li>
        ))}
      </ul>
    </Alert>
  )
}

export default ErrorMessages
