function getApiUrl(urlSubPart) {
    return `https://nibiohn.karadacheck.com/${urlSubPart}`
}

// 開発用　https://192.168.11.211/
function getDevApiUrl(urlSubPart) {
  return `https://nibiohn.karadacheck.com/${urlSubPart}`
}
  
function fetchWithExecption(url = '', options = {}) {
    return fetch(url, options).catch(ex => {
        // console.error(ex)
        return {
        ok: false,
        errors: [`${ex}`],
        }
    })
}

function handleServerErrors(response) {
    if (response.status >= 400) {
      // console.error(`${response.status} ${response.statusText}`)
      return {
        ok: false,
        errors: [response.statusText]
      }
    }
    return response
}

export async function basicGet(urlSubPart) {
    const res = await fetchWithExecption(getApiUrl(urlSubPart), {
        method: 'GET',
    })
    return handleServerErrors(res)
}

export async function basicDevGet(urlSubPart, access) {
  const res = await fetchWithExecption(getDevApiUrl(urlSubPart), {
      method: 'GET',
      credentials:'include',
      headers: {
        'Authorization': `Bearer ${access}`
      },
  })
  return handleServerErrors(res)
}

export async function basicDevAuthGet(urlSubPart) {
  const res = await fetchWithExecption(getDevApiUrl(urlSubPart), {
      method: 'GET',
      credentials:'include',
      mode:'cors'
  })
  return handleServerErrors(res)
}

export async function basicPost(urlSubPart, access, payload) {
    const formData = new FormData()
    formData.set('formData', JSON.stringify(payload))
    const res = await fetchWithExecption(getApiUrl(urlSubPart), {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${access}`
      },
    })
    return handleServerErrors(res)
}

export async function basicDevPost(urlSubPart, access, payload) {
  const bodydata = {formData: payload}
  const res = await fetchWithExecption(getDevApiUrl(urlSubPart), {
    method: 'POST',
    body: JSON.stringify(bodydata),
    headers: {
      "Content-Type": "Application/json",
      'Authorization': `Bearer ${access}`
    },
    mode: "cors",
    credentials:'include',
  })

  return handleServerErrors(res)
}

export async function basicDevLoginPost(urlSubPart, payload) {
  //const formData = new FormData()
  //formData.set('formData', JSON.stringify(payload))
  const bodydata = payload
  const res = await fetchWithExecption(getDevApiUrl(urlSubPart), {
    method: 'POST',
    body: JSON.stringify(bodydata),
    headers: {
      "Content-Type": "Application/json",
    },
    mode: "cors",
    credentials:'include',
  })

  return handleServerErrors(res)
}

export async function basicDevDelete(urlSubPart,access) {
  const res = await fetchWithExecption(getDevApiUrl(urlSubPart), {
      method: 'DELETE',
      credentials:'include',
      headers: {
        'Authorization': `Bearer ${access}`
      },
  })
  return handleServerErrors(res)
}

export default {
    basicGet,
    basicDevGet,
    basicDevAuthGet,
    basicPost,
    basicDevPost,
    basicDevDelete,
    basicDevLoginPost
}