import {
    SELECT_USER,
    SELECT_DATE_KUBUN,
    REGISTER_IMAGES,
    SET_SV,
    SET_ZAIRYO,
    SET_USER_REGISTER_STATUS,
    SET_USERS,
    SET_REGISTERED_MEAL,
    CLEAR_STATE,
    SELECT_IMAGES,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    CLEAR_TOKEN
} from '../actionTypes'

const initState = {
    selectUser: '', 
    selectEatDate: '',
    selectKubun: '',
    registerImages: null,
    selectImages: [],
    sv:[],
    zairyo:[],
    userRegisterStatus: null,
    users:[],
    registeredMeal:[],
    accessToken: null,
    refreshToken: null,
}

const appReducer = (state = initState, action) => {
    //console.log('---dispatch action---', action)
    switch (action.type) {
        case SELECT_USER:
            return {
                ...state,
                selectUser: action.payload.user,
            }
        case SELECT_DATE_KUBUN:
            return {
                ...state,
                selectEatDate: action.payload.eatDate,
                selectKubun: action.payload.kubun,
                selectImages: action.payload.fileNames,
            }
        case REGISTER_IMAGES:
            return {
                ...state,
                registerImages: action.payload.registerImages
            }
        case SET_SV:
            return {
                ...state,
                sv: action.payload.sv
            }
        case SET_ZAIRYO:
            return {
                ...state,
                zairyo: action.payload.zairyo
            }
        case SET_USER_REGISTER_STATUS:
            return {
                ...state,
                userRegisterStatus: action.payload.userRegisterStatus
            }
        case SET_USERS:
            return {
                ...state,
                users: action.payload.users
            }
        case SET_REGISTERED_MEAL:
            return {
                ...state,
                registeredMeal: action.payload.registeredMeal
            }
        case CLEAR_STATE:
            return {
                ...initState
            }
        case CLEAR_TOKEN:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
            }
        case SELECT_IMAGES:
            return {
                ...state,
                selectImages: action.payload.images
            }
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken
            }
        case SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken
            }
        default:
            return state
    }
}

export default appReducer
