import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Alert, Toast, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { InfoCircle } from 'react-bootstrap-icons'
import useCancelableEffect from '../utils/useCancelableEffect'
import ImageGallery from '../components/ImageGallery'
import useAppSelector from '../selectors/appSelector'
import { useForm, Controller } from "react-hook-form"
import useErrorMessagesService from '../services/useErrorMessagesService'
import ErrorMessages from '../components/ErrorMessages'
import Menu from '../components/Menu'

const foodCategories = ['主食','副菜','主菜','牛乳・乳製品','果物','お菓子・加糖飲料','お酒','飲料水・無糖のお茶']

function MealRemove() {
    const [toast, showToast] = useState(false)
    const [completeCheck, setCompleteCheck] = useState({})
    const { errorMessages, initializeErrorMessages, clearErrorMessages } = useErrorMessagesService()
    const { 
        selectUser, 
        selectEatDate, 
        selectKubun, 
        sv, 
        zairyo, 
        selectImages,
        registeredMeal,
        loadRegisteredMeal,
        removeMeal,
        loadSV,
        loadZairyo
    } = useAppSelector()
    const { register, control, handleSubmit, reset, watch, setValue } = useForm({
        defaultValues: {
            mealRegister: []
        }
      });
    const watchAllFields = watch()

    const onClickRemove = async (index) => {
        const resultRemoveMeal = await removeMeal(watchAllFields.mealRegister[index].code,completeCheck[`complete-checkbox${index}`])
        if (resultRemoveMeal.errors) {
            initializeErrorMessages(resultRemoveMeal.errors)
            return
        }
        const registeredMeal = await loadRegisteredMeal(selectUser,selectEatDate,selectKubun)
        if (registeredMeal.errors) {
            initializeErrorMessages(registeredMeal.errors)
            return
        }
        clearErrorMessages()
        handleShowToast()
    }

    const onChangeCompleteCheck = key => (e) => {
        setCompleteCheck({
            ...completeCheck,
            [key]: e.target.checked
        })
    }

    const handleCloseToast = () => showToast(false);
    const handleShowToast = () => showToast(true);

    useCancelableEffect(async () => {
        if(sv.length === 0){
            const resultSV = await loadSV()
            if (resultSV.errors) {
                initializeErrorMessages(resultSV.errors)
                return
            }
        }
        if(zairyo.length === 0){
            const resultZairyo = await loadZairyo()
            if (resultZairyo.errors) {
                initializeErrorMessages(resultZairyo.errors)
                return
            }
        }

        const mealRegister = registeredMeal.mealRegister === undefined ? 
            registeredMeal.find(data => data.userId === selectUser && data.eatDate === selectEatDate && data.kubun === selectKubun).mealRegister 
            : registeredMeal.mealRegister
        let completeChecks = {}
        mealRegister.forEach((meal,index) => completeChecks[`complete-checkbox${index}`] = true)
        setValue(`mealRegister`, mealRegister)
        setCompleteCheck(completeChecks)
        clearErrorMessages()
    },[registeredMeal])
    return (
        <>
            <Menu />
            <div className="mx-auto" style={{width: '95%'}}>
                <div className="row mt-5">
                    <div className='col-12 col-xl-7 mt-4'>
                        <h2 className="">編集</h2>
                        <Alert variant="info">
                            <Alert.Heading>{`ユーザーID：${selectUser} 日付：${selectEatDate} 区分：${selectKubun}`}</Alert.Heading>
                        </Alert>
                        {errorMessages.length > 0 ? (
                            <ErrorMessages className="mt-3" errorMessages={errorMessages} />
                        ) : null}
                        <ImageGallery fileNames={selectImages} filePath={selectUser} imageHeight='500px' samuneHeight='100px' />
                        <Button as={Link} to={`/datecategory/${selectUser}`} className='m-3' variant="secondary" size='lg'>
                            戻る
                        </Button>
                    </div>
                    <div className='col-12 col-xl-5'>
                    {selectUser !== '' && selectEatDate !== '' && selectKubun !== ''?(
                        <div className='' style={{height:'800px',overflowX: 'hidden', overflowY: 'scroll'}}>
                            <Form className='m-4'>
                                <div className="row row-cols-1 justify-content-center">
                                {watchAllFields.mealRegister !== undefined ? watchAllFields.mealRegister.map((item, index) => {
                                    return (
                                        <div key={index} className='border border-dark p-3 col'>
                                            <h3 key={`h3-${index}`}>{`料理${index+1}`}</h3>
                                            <Row>
                                                <Form.Group key={`category-FG-${index}`} className="mb-3 text-start ps-1" as={Col}>
                                                    <Form.Label className='mb-0' key={`category-FL-${index}`}>料理カテゴリー*</Form.Label>
                                                        <Form.Select value={item.category} key={`category-FS-${index}`} required disabled readOnly>
                                                            <option value="" key={`category-o-${index}-`}>カテゴリーを選択してください</option>
                                                            {foodCategories.map((foodCategory,foodCategoryIndex) => (
                                                                <option value={foodCategory} key={`category-o-${index}-${foodCategoryIndex}`}>{foodCategory}</option>
                                                            ))}
                                                            <option value="欠食" key={`foodName-o-${index}-none`}>欠食</option>
                                                        </Form.Select>
                                                </Form.Group>
                                                {foodCategories.map((foodCategory,foodCategoryIndex) => (
                                                    <Form.Group 
                                                        className={item.category === foodCategory ? "mb-3 text-start ps-1 pe-1" : "mb-3 d-none"} 
                                                        key={`foodName-FG-${index}-${foodCategoryIndex}`}
                                                        as={Col}
                                                    >
                                                        <Form.Label className='mb-0' key={`foodName-FL-${index}-${foodCategoryIndex}`}>
                                                            料理名*
                                                        </Form.Label>
                                                        <Form.Select key={`foodName-FS-${index}-${foodCategoryIndex}`} value={item.foodName} required={item.category === foodCategory ? true : false} disabled readOnly>
                                                            <option value="" key={`foodName-o-${index}-${foodCategoryIndex}`}>料理名を選択してください</option>
                                                            {sv.filter(data => data.category === foodCategory).map((filterData,index) => (
                                                                <option value={filterData.foodName} key={`foodName-o-${index}-${foodCategoryIndex}`}>{filterData.foodName}</option>)
                                                            )}
                                                            <option value="該当なし" key={`foodName-o-${index}-${foodCategoryIndex}-none`}>該当なし</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                ))}
                                            </Row>
                                            <Controller
                                                render={({ field }) => (
                                                    <Form.Group className="mb-3 text-start d-none" {...field}>
                                                        <Form.Label className='mb-0'>料理ID*</Form.Label>
                                                            <Form.Control defaultValue={item.ryouriId} disabled readOnly/>
                                                    </Form.Group>
                                                )}
                                                name={`mealRegister.${index}.ryouriId`}
                                                control={control}
                                                key={`ryouriId-${index}`}
                                            />
                                            <Controller
                                                render={({ field }) => (
                                                    <Form.Group className="mb-3 text-start d-none" {...field}>
                                                        <Form.Label className='mb-0'>識別子*</Form.Label>
                                                            <Form.Control defaultValue={item.code} disabled readOnly/>
                                                    </Form.Group>
                                                )}
                                                name={`mealRegister.${index}.code`}
                                                control={control}
                                                key={`code-${index}`}
                                            />
                                            <Form.Group className="mb-3 text-start" key={`eatFoodName-FG-${index}`}>
                                                <Form.Label className='mb-0' key={`eatFoodName-FL-${index}`}>実際の料理</Form.Label>
                                                <Form.Control value={item.eatFoodName} key={`eatFoodName-FC-${index}`} disabled readOnly/>
                                            </Form.Group>
                                            <Row>
                                                <div className="text-start fs-4 border-top mb-2 mt-2">
                                                    SV値
                                                </div>
                                                <Form.Group className="mb-3 text-start ps-1" key={`shushoku-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`shushoku-FL-${index}`} style={{color: "gold"}}>
                                                        主食
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).shushoku !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).shushoku}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.syusyokuSV} type="number" step={0.1} key={`shushoku-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start ps-1" key={`fukusai-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`fukusai-FL-${index}`} style={{color: "DarkGreen"}}>
                                                        副菜
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).fukusai !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).fukusai}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.fukusaiSV} type="number" step={0.1} key={`fukusai-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start ps-1" key={`shusai-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`shusai-FL-${index}`} style={{color:"crimson"}}>
                                                        主菜
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).shusai !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).shusai}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.syusaiSV} type="number" step={0.1} key={`shusai-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start ps-1" key={`nyuu-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`nyuu-FL-${index}`} style={{color:"purple"}}>
                                                        牛乳・乳製品
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).nyuu !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).nyuu}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.nyuuSV} type="number" step={0.1} key={`nyuu-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start ps-1 pe-1" key={`kudamono-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`kudamono-FL-${index}`} style={{color:"darkturquoise"}}>
                                                        果物
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).kudamono !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).kudamono}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.kudamonoSV} type="number" step={0.1} key={`kudamono-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                            </Row>
                                            <Row>
                                                <div className="text-start fs-4 border-top mb-2  mt-1">
                                                    カロリー
                                                </div>
                                                <Form.Group className="mb-3 text-start ps-1" key={`kashi-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`kashi-FL-${index}`}>
                                                        お菓子・加糖飲料（kcal）
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).kashi !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).kashi}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.kashical} type="number" step={10} key={`kashi-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start ps-1" key={`sake-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`sake-FL-${index}`}>
                                                        お酒（kcal）
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).sake !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).sake}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.sakecal} type="number" step={10} key={`sake-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <Form.Group className="mb-3 text-start mt-1 d-none" key={`energy-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`energy-FL-${index}`}>
                                                        料理カロリー
                                                    </Form.Label>
                                                    <Form.Control value={item.energy} type="number" step={10} key={`energy-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                                <div className="text-start fs-4 border-top mb-2  mt-1" key={`quantity-title-${index}`}>
                                                    量
                                                </div>
                                                <Form.Group className="mb-3 text-start ps-1" key={`inryou-FG-${index}`} as={Col}>
                                                    <Form.Label className='mb-0' key={`inryou-FL-${index}`}>
                                                        飲料水・無糖のお茶（ml）
                                                        {item.ryouriId !== undefined && item.ryouriId !== null && item.ryouriId !== "" &&
                                                            zairyo.find(element => element.id === item.ryouriId).inryou !== "" ? (
                                                                <OverlayTrigger
                                                                    //show={true}
                                                                    delay={{ hide: 5000 }}
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            {zairyo.find(element => element.id === item.ryouriId).inryou}
                                                                        </Tooltip>                                                
                                                                    }
                                                                >
                                                                    <span className='text-muted'>
                                                                        <InfoCircle className="ms-1 ReactBootstrapIcon" />
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )
                                                        :null}
                                                    </Form.Label>
                                                    <Form.Control value={item.mizuryou} type="number" step={10} key={`inryou-FC-${index}`} disabled readOnly/>
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3 text-start" key={`memo-FG-${index}`}>
                                                <Form.Label className='mb-0' key={`memo-FL-${index}`}>
                                                    メモ
                                                </Form.Label>
                                                <Form.Control as="textarea" rows={3} value={item.memo} key={`memo-FC-${index}`} disabled readOnly/>
                                            </Form.Group>
                                            <div className='d-flex justify-content-center mt-3 mb-3'>
                                                <Form.Check 
                                                    type='checkbox'
                                                    id={`complete-checkbox${index}`}
                                                    label='登録完了の場合チェックを入れる'
                                                    checked={completeCheck[`complete-checkbox${index}`]}
                                                    onChange={onChangeCompleteCheck(`complete-checkbox${index}`)}
                                                />
                                            </div>
                                            <Button className='mb-1' variant="danger" onClick={() => onClickRemove(index)} key={`remove-${index}`}>
                                                削除
                                            </Button>
                                        </div>
                                    )
                                }): null}
                                </div>
                            </Form>
                            {/* メッセージ */}
                            <div className="d-flex justify-content-center m-3">
                                <Toast onClose={handleCloseToast} show={toast} delay={10000} bg='success' autohide>
                                    <Toast.Header/>
                                    <Toast.Body className='text-white lead'>
                                        削除に成功しました
                                    </Toast.Body>
                                </Toast>
                            </div>
                        </div>
                    ): null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MealRemove;