import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Menu from '../components/Menu'

function NotFound() {
  return (
    <>
      <Menu />
      <div id="NotFound" className="w-100 p-3">
        <h1>Not Found</h1>
        <Button as={Link} to="/" className='m-3' variant="secondary">
            戻る
        </Button>
      </div>
    </>
  )
}

export default NotFound