import './App.css';
import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";
import UserList from './pages/UserList'
import DateCategory from './pages/DateCategory';
import MealRegister from './pages/MealRegister';
import MealEdit from './pages/MealEdit';
import MealRemove from './pages/MealRemove';
import NotFound from './pages/NotFound';
import Login from './pages/Login';

import useAppSelector from './selectors/appSelector'
import useCancelableEffect from './utils/useCancelableEffect'

function App() {
  const { refreshToken, accessToken, checkToken, loadRefreshToken, loadAccessToken } = useAppSelector()
  const navigate = useNavigate()
  const location = useLocation()
  const authCheck = async () => {
    // Stateが初期値なら
    if (refreshToken === null || refreshToken === undefined) {
      const resultRefresh = await loadRefreshToken()
      // CookieにないOR期限切れなら
      if (resultRefresh.errors) {
        navigate('/')
        return
      }
      const resultAccess = await loadAccessToken(resultRefresh.refresh_token)
      // CookieにないOR期限切れなら
      if (resultAccess.errors) {
        navigate('/')
        return
      }
      return
    }
    if (accessToken === null) {
      const result = await loadAccessToken(refreshToken)
      // CookieにないOR期限切れなら
      if (result.errors) {
        navigate('/')
        return
      }
      return
    }
    // 期限切れチェック
    const refreshExpired = await checkToken(refreshToken)
    if (refreshExpired.errors) {
      const result = await loadRefreshToken()
      if (result.errors) {
        navigate('/')
        return
      }
    }
    const accessExpired = await checkToken(accessToken)
    if (accessExpired.errors) {
      const result = await loadAccessToken(refreshToken)
      if (result.errors) navigate('/')
      return
    }
  }

  useCancelableEffect(async () => {
    // デバッグ用
    console.log(location.pathname)
    await authCheck()
  },[location.pathname])
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/userlist" element={<UserList />}/>
        <Route path="/datecategory/:id" element={<DateCategory />}>
        </Route>
        <Route path="/mealregister/" element={<MealRegister />}/>
        <Route path="/mealedit/" element={<MealEdit />}/>
        <Route path="/mealremove/" element={<MealRemove />}/>
        <Route path="*" element={<NotFound />}/>
      </Routes>
    </div>
  );
}

export default App;
