import React from 'react'
import { useNavigate } from "react-router-dom";
import { Button, Form } from 'react-bootstrap'
import useAppSelector from '../selectors/appSelector'
import useErrorMessagesService from '../services/useErrorMessagesService'
import ErrorMessages from '../components/ErrorMessages'
import { useForm } from "react-hook-form";
import Menu from '../components/Menu'

function Login() {
    const { login } = useAppSelector()
    const { errorMessages, initializeErrorMessages, clearErrorMessages } = useErrorMessagesService()
    const { register, handleSubmit } = useForm()
    const navigate = useNavigate()


    const onSubmit = async (data) => {
        const loginResult = await login(data.mail,data.password)
        if(loginResult.errors){
            initializeErrorMessages(loginResult.errors)
            return
        }
        navigate('/userlist')
        clearErrorMessages()
    }
    return (
      <>
        <Menu />
        <h1 className="m-5">ログイン</h1>
        <div className="w-50 mx-auto">
            <Form className="w-50 mx-auto" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3 text-start" controlId="formBasicEmail">
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="email" placeholder="Enter id" required  {...register("mail")}/>
                </Form.Group>
                <Form.Group className="mb-3 text-start" controlId="formBasicPassword">
                    <Form.Label>パスワード</Form.Label>
                    <Form.Control type="password" placeholder="Password" required  {...register("password")}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
            {errorMessages.length > 0 ? (
                <ErrorMessages className="mt-3" errorMessages={errorMessages} />
            ) : null}
        </div>
      </>
    );
}

export default Login;