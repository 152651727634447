import {
    SELECT_USER,
    SELECT_DATE_KUBUN,
    REGISTER_IMAGES,
    SET_SV,
    SET_ZAIRYO,
    SET_USER_REGISTER_STATUS,
    SET_USERS,
    SET_REGISTERED_MEAL,
    CLEAR_STATE,
    SELECT_IMAGES,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    CLEAR_TOKEN
} from './actionTypes'
  
export const selectUser = (user) => ({
    type: SELECT_USER,
    payload: {
      user,
    },
})

export const selectDateKubun = (eatDate, kubun, fileNames) => ({
    type: SELECT_DATE_KUBUN,
    payload: {
      eatDate,
      kubun,
      fileNames
    },
})

export const setRegisterImages = (registerImages) => ({
    type: REGISTER_IMAGES,
    payload: {
        registerImages
    },
})

export const setSV = (sv) => ({
    type: SET_SV,
    payload: {
        sv
    },
})

export const setZairyo = (zairyo) => ({
    type: SET_ZAIRYO,
    payload: {
        zairyo
    },
})

export const setUserRegisterStatus = (userRegisterStatus) => ({
    type: SET_USER_REGISTER_STATUS,
    payload: {
        userRegisterStatus
    },
})

export const setUsers = (users) => ({
    type: SET_USERS,
    payload: {
        users
    },
})

export const setRegisteredMeal = (registeredMeal) => ({
    type: SET_REGISTERED_MEAL,
    payload: {
        registeredMeal
    },
})

export const clearState = () => ({
    type: CLEAR_STATE,
})

export const clearToken = () => ({
    type: CLEAR_TOKEN,
})

export const selectImages = (images) => ({
    type: SELECT_IMAGES,
    payload: {
        images
    },
})

export const setAccessToken = (accessToken) => ({
    type: SET_ACCESS_TOKEN,
    payload: {
        accessToken
    },
})

export const setRefreshToken = (refreshToken) => ({
    type: SET_REFRESH_TOKEN,
    payload: {
        refreshToken
    },
})

export default {
    selectUser,
    selectDateKubun,
    setRegisterImages,
    setSV,
    setZairyo,
    setUserRegisterStatus,
    setUsers,
    setRegisteredMeal,
    clearState,
    selectImages,
    setAccessToken,
    setRefreshToken,
    clearToken
}