import { useState, useEffect, useRef } from 'react'

function useErrorMessagesService() {
  const canceled = useRef(false);
  const [errorMessages, setErrorMessages] = useState([])

  const initializeErrorMessages = (errorMessages = []) => {
    setErrorMessages(errorMessages)
  }

  const addErrorMessage = (errorMessage = '') => {
    setErrorMessages(errorMessages.concat([errorMessage]))
  }

  const clearErrorMessages = () => {
    //if (canceled.current) return
    setErrorMessages([])
  }

  useEffect(() => () => canceled.current = true, [])

  return {
    errorMessages,
    initializeErrorMessages,
    addErrorMessage,
    clearErrorMessages,
  }
}

export default useErrorMessagesService
