import { Container, Nav, Navbar, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import useAppSelector from '../selectors/appSelector'
import { useNavigate } from "react-router-dom";

function Menu() {
    const { logout,clearToken } = useAppSelector()
    const navigate = useNavigate()

    
    const onClick = async () => {
        const result = await logout()
        if (result.errors) {
            return
        }
        clearToken()
        navigate('/')
    }
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand>食事データ入力サイト</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link as={Link} to='/userlist'>被験者一覧</Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
                <Button variant="light" onClick={onClick}>ログアウト</Button>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Menu;