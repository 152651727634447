import React from 'react'
import { Link } from 'react-router-dom'
import { ListGroup, Tabs, Tab } from 'react-bootstrap'
import useCancelableEffect from '../utils/useCancelableEffect'
import useAppSelector from '../selectors/appSelector'
import useErrorMessagesService from '../services/useErrorMessagesService'
import ErrorMessages from '../components/ErrorMessages'
import Menu from '../components/Menu'

function UserList() {
    const { users, accessToken, loadUsers} = useAppSelector()
    const { errorMessages, initializeErrorMessages, clearErrorMessages } = useErrorMessagesService()

    const userList = (users) => {
        let listGroups = []
        for(let i = 0; i < users.length;i+=30){
            listGroups.push(
                <ListGroup key={`listGroup${i}`} className="w-auto">
                    {users.slice(i, i+30).map((user,index) => (
                        <Link key={`link${index}`} to={`/datecategory/${user.userId}`}>
                            <ListGroup.Item key={`item${index}`} className='fs-3'>{user.userId}</ListGroup.Item>
                        </Link>
                    ))}
                </ListGroup>
            )
        }
        return listGroups
    }

    const registerStatusTab = (users) => {
        let registeredUser = []
        let unregisteredUser = []

        users.forEach((user) => {
            const registerComplete = Object.keys(user).some(key => user[key] === false)
            if (registerComplete === true) {
                unregisteredUser.push(user)
            }else{
                registeredUser.push(user)
            }
        })

        return (
            <Tabs
                defaultActiveKey="registerImcomplete"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="registerComplete" title={`登録完了（${registeredUser.length}件）`}>
                    <div className="d-flex justify-content-center m-5">
                        {userList(registeredUser)}
                    </div>  
                </Tab>
                <Tab eventKey="registerImcomplete" title={`未登録・登録中（${unregisteredUser.length}件）`}>
                    <div className="d-flex justify-content-center m-5">
                        {userList(unregisteredUser)}
                    </div>
                </Tab>
            </Tabs>
        )

    }

    useCancelableEffect(async () => {
        /* 被験者情報APIから被験者一覧を取得 */
        if(accessToken === null) return
        if(users.length !== 0) return
        const resultUsers = await loadUsers()
        if (resultUsers.errors) {
            initializeErrorMessages(resultUsers.errors)
            return
        }
        clearErrorMessages()
    },[])
    useCancelableEffect(async () => {
        if(accessToken === null) return
        if(users.length !== 0) return
        const resultUsers = await loadUsers()
        if (resultUsers.errors) {
            initializeErrorMessages(resultUsers.errors)
            return
        }
        clearErrorMessages()
    },[accessToken])
    
    return (
      <>
        <Menu />
        <h1 className="m-5">被験者一覧</h1>
        <div className="w-50 mx-auto">
            {errorMessages.length > 0 ? (
                <ErrorMessages className="mt-3" errorMessages={errorMessages} />
            ) : null}
            {users.length > 0 ? registerStatusTab(users) : null}
        </div>
      </>
    );
}

export default UserList;