import { useSelector, useDispatch } from 'react-redux'
import Actions from '../redux/actions'
import API from '../repositories/api'

//import sv_mock from '../__mocks__/sv'
//import zairyo_mock from '../__mocks__/zairyo'

export function useAppSelector() {
    const dispatch = useDispatch()
    const selectUser = useSelector(state => state.selectUser)
    const selectEatDate = useSelector(state => state.selectEatDate)
    const selectKubun = useSelector(state => state.selectKubun)
    const registerImages = useSelector(state => state.registerImages)
    const sv = useSelector(state => state.sv)
    const zairyo = useSelector(state => state.zairyo)
    const selectImages = useSelector(state => state.selectImages)
    const userRegisterStatus = useSelector(state => state.userRegisterStatus)
    const users = useSelector(state => state.users)
    const registeredMeal = useSelector(state => state.registeredMeal)
    const refreshToken = useSelector(state => state.refreshToken)
    const accessToken = useSelector(state => state.accessToken)

    const login = async (email, password) => {
        const result = await API.login(email, password)
        dispatch(Actions.setRefreshToken(result.refresh_token))
        dispatch(Actions.setAccessToken(result.access_token))
        return result
    }

    const logout = async () => {
        const result = await API.logout()
        return result
    }

    const loadRefreshToken = async () => {
        const result = await API.getRefreshToken()
        dispatch(Actions.setRefreshToken(result.refresh_token))
        return result
    }

    const loadAccessToken = async (refreshToken) => {
        const result = await API.getAccessToken(refreshToken)
        dispatch(Actions.setAccessToken(result.access_token))
        return result
    }

    const checkToken = async (token) => {
        const result = await API.checkToken(token)
        return result
    }

    const loadSV = async () => {
        const result = await API.getRyouriSV()
        dispatch(Actions.setSV(result))
        return result
    }
    const loadZairyo = async () => {
        const result = await API.getRyouriZairyo()
        dispatch(Actions.setZairyo(result))
        return result
    }
    /* CORSでローカルから取得できない場合に
    const loadSV = async () => {
        const result = await API.getRyouriSV()
        dispatch(Actions.setSV(sv_mock))
        return result
    }
    const loadZairyo = async () => {
        const result = await API.getRyouriZairyo()
        dispatch(Actions.setZairyo(zairyo_mock))
        return result
    }*/

    const loadUser = async (userId) => {
        const result = await API.getUser(userId,accessToken)
        dispatch(Actions.setUserRegisterStatus(result))
        return result
    }

    const loadUsers = async () => {
        const result = await API.getUser('',accessToken)
        dispatch(Actions.setUsers(result))
        return result
    }

    const loadMealImages = async (userId) => {
        const result = await API.getMealImage(userId,accessToken)
        dispatch(Actions.setRegisterImages(result))
        return result
    }

    const loadRegisteredMeal = async (userId,date='',kubun='') => {
        const result = await API.getMeal(userId,accessToken,date,kubun)
        dispatch(Actions.setRegisteredMeal(result))
        return result
    }

    const postMeal = async (formData) => {
        const result = await API.postMeal(formData,accessToken)
        return result
    }

    const removeMeal = async (code,complete) => {
        const result = await API.removeMeal(code,complete,accessToken)
        return result
    }

    const setUser = (userId) => dispatch(Actions.selectUser(userId))
    const setRegisterImage = (images) => dispatch(Actions.setRegisterImages(images))
    const setDateKubun = (date,kubun,fileNames) => dispatch(Actions.selectDateKubun(date,kubun,fileNames))
    const clearToken = () => dispatch(Actions.clearToken())
    const setImages = (images) => dispatch(Actions.selectImages(images))

    return {
        selectUser,
        selectEatDate,
        selectKubun,
        registerImages,
        sv,
        zairyo,
        selectImages,
        userRegisterStatus,
        users,
        registeredMeal,
        refreshToken,
        accessToken,
        
        login,
        logout,
        loadRefreshToken,
        loadAccessToken,
        checkToken,
        loadSV,
        loadZairyo,
        loadUser,
        loadUsers,
        loadMealImages,
        loadRegisteredMeal,
        postMeal,
        removeMeal,
        setUser,
        setRegisterImage,
        setDateKubun,
        clearToken,
        setImages,
    }
}

export default useAppSelector