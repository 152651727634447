import { useEffect } from 'react'

function useCancelableEffect(effect = () => {}, deps = []) {
  if (typeof effect !== 'function') {
    throw new Error('Invalid arguments: first argument should be a function')
  }
  useEffect(() => {
    let canceled = false
    let teardown = () => {}
    ;(async () => {
      teardown = await effect(() => canceled)
    })()
    return () => {
      if (typeof teardown === 'function') {
        teardown()
      }
      canceled = true
    }
  }, deps)
}

export default useCancelableEffect
