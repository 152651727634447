import apiUtils from './apiUtils'


export const login = async (email, password) => {
  const res = await apiUtils.basicDevLoginPost('auth/publish/', {"email": email,"password": password})
  if (!res.ok) {
    return {
      errors: ['ログインに失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const logout = async () => {
  const res = await apiUtils.basicDevAuthGet(`auth/logout/`)
  if (!res.ok) {
    return {
      errors: ['ログアウトに失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const getRefreshToken = async () => {
  const res = await apiUtils.basicDevAuthGet(`auth/refresh-get/`)
  if (!res.ok) {
    return {
      errors: ['データの取得に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const getAccessToken = async (refreshToken) => {
  const res = await apiUtils.basicDevLoginPost(`auth/access-get/`,{"refresh": refreshToken})
  if (!res.ok) {
    return {
      errors: ['データの取得に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const checkToken = async (token) => {
  const res = await apiUtils.basicDevLoginPost(`auth/verify/`,{"token": token})
  if (!res.ok) {
    return {
      errors: ['トークンの認証に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const getRyouriSV = async () => {
    const res = await apiUtils.basicGet('api/sv')
    if (!res.ok) {
      return {
        errors: ['SV値の取得に失敗しました'],
      }
    }
    const body = await res.json()
    return body
}

export const getRyouriZairyo = async () => {
    const res = await apiUtils.basicGet('api/zairyo')
    if (!res.ok) {
      return {
        errors: ['材料の取得に失敗しました'],
      }
    }
    const body = await res.json()
    return body
}

export const getMealImage = async (userId,access) => {
  const res = await apiUtils.basicDevGet(`db_api/eaten_image/list/${userId}`,access)
  if (!res.ok) {
    return {
      errors: ['食事画像の取得に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const postMeal = async (formData,access) => {
  const res = await apiUtils.basicDevPost('db_api/record/register/',access, formData)
  if (!res.ok) {
    return {
      errors: ['データの登録に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const removeMeal = async (code,complete,access) => {
  const res = await apiUtils.basicDevDelete(`db_api/record/delete/${code}?complete=${complete}`,access)
  if (!res.ok) {
    return {
      errors: ['データの削除に失敗しました'],
    }
  }
  const body = ''
  return body
}

export const getMeal = async (userId,access,date='',kubun='') => {
  let query = '?'
  if (date !== '') query+=`date=${date}&`
  if (kubun !== '') query+=`kubun=${kubun}`

  const res = await apiUtils.basicDevGet(`db_api/record/list/${userId}${query}`,access)
  if (!res.ok) {
    return {
      errors: ['データの取得に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export const getUser = async (userId,access) => {
  const res = await apiUtils.basicDevGet(`db_api/testee_register_stats/list/${userId}`,access)
  if (!res.ok) {
    return {
      errors: ['データの取得に失敗しました'],
    }
  }
  const body = await res.json()
  return body
}

export default {
  login,
  logout,
  getRefreshToken,
  getAccessToken,
  checkToken,
  getRyouriSV,
  getRyouriZairyo,
  getMealImage,
  postMeal,
  removeMeal,
  getMeal,
  getUser
}